<script lang="ts" setup generic="">
const props = defineProps<{
  items: TabListItem[]
  defaultItem?: string
  boxed?: boolean
  lifted?: boolean
  bordered?: boolean
}>()

export interface TabListItem {
  text: string
  key: string
}
const activeTab = defineModel()

const tabsStyle = computed(() => {
  return {
    'tabs-boxed': props.boxed,
    'tabs-lifted': props.lifted,
    'tabs-bordered': props.bordered,
  }
})
</script>

<template>
  <div role="tablist" class="tabs" :class="tabsStyle">
    <a v-for="item in items" :key="item.key" role="tab" class="tab transition-colors" :class="{ 'tab-active': activeTab === item.key }" @click="activeTab = item.key">
      <slot :name="`before-tab-${item.key}`" />{{ item.text }}<slot :name="`after-tab-${item.key}`" />
    </a>
  </div>
</template>
